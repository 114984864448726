.logo-container {
  display: block;
  align-items: center;
  margin: auto;
}

.form-container {
  display: block;
  align-items: center;
  margin: auto;
  margin-bottom: 40px;
  width: 45%;
  outline: 2px solid;
  outline-color: green;
  outline-width: thick;
  padding: 50px;
}

.workout {
  display: relative;
  align-items: right;
  margin: auto;
  width: 30%;
}

.workout-2 {
  display: block;
  align-items: left;
  margin: auto;
  width: 30%;
}

.enroll-now {
  display: block;
  align-items: center;
  margin: auto;
  width: 45%;
  font-size: 20px;
  padding-top: 30px;
}
